#cybersol_div {
  padding: 0 15% 0px 15%;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
#buttons_div {
  justify-content: center;
  align-items: center;
}

.backimage {
  background-image: url("http://trustthink.net/images/IIOT.jpg");
  opacity: 0.2;
}

.button_main {
  background-color: #e45555;
  border: solid 2px #e45555;
}
#cybersol_text_div {
  padding: 30px 0;
}

#cybersol_text_div h1 {
  margin: 0;
  padding: 0 0 30px 0;
  font-family: open sans;
  font-weight: 300;
  color: #e2e2e2;
  font-size: 75px;
  line-height: 50px;
  text-align: center;
}

.headerCenter {
  padding-top: 1%;
  margin: 0;
  padding-right: 30%;
  padding-left: 30%;
  font-family: open sans;
  font-weight: 300;
  color: #000000;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}

.pCenter {
  font-family: open sans;
  color: #000000;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  padding: 0;
  margin: 0;
}

.whiteText {
  color: #e2e2e2;
}

#buttons_divv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
}
.fontSmall {
  font-size: 15px;
}
.autowidth {
  height: auto;
  width: 100%;
}

.autowidthBook {
  width: 50%;
}
.boxer h1 {
  padding-top: 3%;
  text-align: center;
  font-size: 190%;
}
.boxer p {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 25%;
}

.boxerNew {
  font-family: open sans;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  max-width: 200rem;
  min-width: 20rem;
  padding-bottom: 1%;
  margin: 10px;
  text-align: left;
  width: 33.33%;
  vertical-align: bottom;
}
.padIMG {
  padding-right: 15px;
}
.fontSizeP {
  font-size: 17px;
  text-align: left;
  padding-left: 10%;
}
.smallsmall {
  font-size: 75%;
  font-family: open sans;
}
.button_main {
  background-color: #4180df;
  border: solid 2px #ffffff;
}

@media only screen and (max-width: 767px) {
  #cybersol_text_div h1 {
    font-size: 28px;
    line-height: 30px;
  }

  #cybersol_div {
    padding: 0 5% 0px 5%;
  }

  #buttons_div {
    flex-direction: column;
  }
}
