.textbox2 {
  align-items: center;
  white-space: pre-line;
  margin-top: 1rem;
}

.posttext {
  margin-left: 13px;
  margin-top: 1%;
}

.post {
  padding-left: 2%;
  padding-right: 2%;
}

.profilepicture {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 48px;
  height: 48px;
}
.date {
  margin-top: 7%;
  font-size: small;
}

.poster {
  display: flex;
}

.postimg {
  width: 75%;
  height: auto;
}

.pstimggrid {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 7%;
  margin-left: 10%;
}

.pstreportgrid{
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 7%;
  margin-left: 10%;

}
.content-container {
  margin-right: auto;
  margin-top: -1.5%;
  font-size: medium;
  line-height: 2rem;
  margin-bottom: 5rem;
  background-color: white;
}

.hashtag {
  color: #1da1f2;
}



.newsbg {
  padding-top: 2%;
  height: 100%;
}

.textbox2 img {
  width: 40%;
  height: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 700px) {
  .react-pdf__Document {
    margin: 0 auto;
    width: 250px !important;
    height: auto !important;
  }
  .react-pdf__Page {
    margin: 0 auto;
    width: 250px !important;
    height: auto !important;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 250px !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 1400px) {
  
  .content-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  .textbox2 h4 {
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
  }

  .navl2 {
    padding-right: 5rem;
  }

  .textbox2 p {
    margin: 0;
    padding: 0 1rem 0 1rem;
    font-size: medium;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .textbox2 img {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .postimg {
    margin-top: 1%;
    margin-left: 5%;
  }

  .pstimggrid {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 7%;
    margin-left: 0;
  }
}
