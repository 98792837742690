#nav_bar {
  padding: 1% 15% 0 10%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-self: stretch;
  background-color: white;
}

#nav_logo_div {
  height: 1%;
  text-align: left;
  left: 50px;
}

#nav_logo {
  content: url("../../img/BLACKlogo-sm.webp");
  margin-right: 0px;
  width: 100%;
  display: inline-block;
  margin-top: 1rem;
  height: 5rem;
  padding-bottom: 1rem;
}

#nav_list_items_div {
  display: flex;
  justify-content: center;
  align-items: right;
  margin-left: 22%;
  margin-top: 3%;
}

#nav_list {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 3rem;
  align-content: center;
  justify-content: center;
}

.nav_list_item {
  padding: 0 0;
  font-family: Montserrat;
}

.nav_list_item a {
  text-decoration: none;
  color: black;
  font-family: Montserrat;
  text-transform: uppercase;
  transition: color 0.25s ease;
}

.nav_list_item a:hover {
  color: #4180df;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 20px;
  right: 32px;
  top: 32px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #0e0000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 48px;
  width: 48px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: relative;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fafbff;
  padding-top: 0;

  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  overflow-y: scroll;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.display_none {
  display: none;
}

/* Dropdown Button */
.dropbtn {
  background-color: #1a2923;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  
}



/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

#contact a {
  color: #0e0000;
}

@media only screen and (max-width: 1400px) {
  .display_none {
    display: block;
  }

  .navarrow {
    bottom: 0.45rem;
  }

  #nav_logo {
    width: auto;
    max-width: 15rem;
    height: auto;
    max-height: 20rem;
    margin: 0%;
    margin-bottom: 0;
    margin-top: 2%;
  }

  #nav_bar {
    padding: 0 0 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
  }

  .nav_list_item {
    margin: 0;
    padding: 1rem;
    font-size: medium;
    font-family: Montserrat;
    border-style: solid;
    border-left: none;
    border-right: none;
    background: rgba(20, 144, 163, 0.2);
  }

  .nav_list_item3 {
    margin: 0;
    padding: 1rem;
    font-size: medium;
    font-family: Montserrat;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: rgba(20, 144, 163, 0.2);
  }

  .nav_list_item2 {
    margin: 0;
    padding: 1rem;
    margin-left: 3rem;
    font-size: medium;
    font-family: Montserrat;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: none;
    background: rgba(20, 144, 163, 0.1);
  }

  #nav_list_items_div {
    display: none;
    justify-content: center;
    align-items: left;
    margin-left: 0%;
    margin-top: -2%;
    font-size: 100%;
  }

  #nav_list {
    margin-top: 5rem;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: baseline;
    margin-left: 0%;

    text-align: left;
  }
}
