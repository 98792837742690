.link_margins {
  margin-left: 35rem;
  margin-right: 35rem;
}

.ulmarg {
  margin: 0 20% 0 25%;
  padding: 0 10rem 0 5rem;
}

.pmarg {
  margin: 0 0 1rem 2rem !important;
  padding: 0 !important;
}

@media only screen and (max-width: 1400px) {
  .link_margins {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .ulmarg {
    margin: 0 auto 0 auto;
    padding: 0 5% 0 5%;
  }
}
