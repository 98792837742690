/* Note this stylesheet is only used for the home page of the Industries category. */




#cybersol_div {
  padding: 0 15% 0px 15%;
  min-height: 0vh;
  padding-top: 1%;
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Industries Header */
#title {
  position: relative;
  display: flex;
  flex-direction: column;
  right: 40%;
  top: 100%;
  padding-bottom: 30px;
  align-content: left;
  align-self: stretch;
  background-position: contain;
  overflow: hidden;
}

#description {
  font-size: 80px;
}

/* Subheader */
.headerInd {
  padding-top: 2%;
  padding-bottom: 2%;
  font-family: open sans;
  font-size: 25px;
  text-decoration: underline;
}

.column4 {
  flex: 50%;
  padding-bottom: 2rem;
  margin-top: 5%;
  width: 80%;
}

/* 2x2 Grid for Bodies of Text */
.grid4 {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  margin-left: 12%;
  margin-bottom: 2%;
  margin-top: 2%;
}


.threeCardCentered {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  margin-left: 12%;
  margin-bottom: 2%;
  margin-top: 2%;
}

/* Main text body */
.boxerInd {
  font-family: open sans;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: top;
  padding-bottom: 5%;
  padding-right: 0px;
  text-align: left;
  vertical-align: bottom;
  line-height: 2;
  position: relative;
  right: 50px;

  /* Paragraph width */
  max-width: 40rem;
}

.highfont {
  font-size: 120%;
  font-weight: 430;
}

/* Image stylization */
.pictures {
}

.centeralign {
  text-align: center;
}

.def_home_img {
  content: url("../../img/defense_home-sm.webp");
  display: block;
  overflow: hidden;
}

.health_home_img {
  content: url("../../img/healthcare_home-sm.webp");
  display: block;
  overflow: hidden;
}

.transport_home_img {
  content: url("../../img/transportation_home-sm.webp");
  display: block;
  overflow: hidden;
}

@media only screen and (max-width:418px){
  .threeCardCentered{
    display: inline-grid;
    grid-template-columns: 100%;
    column-gap: 5%;
    margin: auto auto;
    padding: 2rem 1rem 0 1rem;
  }
}

@media only screen and (min-width:418px) and (max-width: 700px){
  .threeCardCentered{
    display: inline-grid;
    grid-template-columns: 100%;
    margin: 1rem 0 0 1rem;
    column-gap: 5%;
    padding: 2rem 1rem 0 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .industry_img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .column4 {
    flex: 50%;
    padding-bottom: 2rem;
    margin-top: 2rem;
    width: 100%;
  }

  .column4 p {
    font-size: large;
  }

  /* 2x2 Grid for Bodies of Text */
  .grid4 {
    display: grid;
    grid-template-columns: 100%;
    margin: 0;
  }

  .pictures {
    width: 100%;
    height: 100%;
    display: inline-block;
    /* Float images side by side to the left */
    /* Align captions under images */
    text-align: center;
  }
}

#cybersol_text_div h1 {
  margin: 0;
  padding: 0 0 15px 0;
  font-family: open sans;
  font-weight: 300;
  color: #e2e2e2;
  font-size: 75px;
  line-height: 50px;
  text-align: center;
}

.containerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
  width: 80rem;
}
.autowidth {
  width: 100%;
  height: auto;
}

.boxer h1 {
  padding-top: 3%;
  text-align: left;
  font-size: 190%;
}
.boxer p {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 25%;
}

/* 
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .read_more_btn{
    @apply inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300;

  }

  .card{
    @apply max-w-sm bg-white border border-gray-200 rounded-lg shadow-lg;
  }

  .card_head{
    @apply mb-2 text-2xl font-bold tracking-tight text-gray-900;
  }
} */
