html,body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.main_marg1 {
  font-family: open sans;
  margin-left: 15%;
  margin-right: 19%;
}
.centerPhero {
  text-align: center;
}

.row {
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
}

/* Columns for Services */
.column {
  flex: 50%;
  margin-top: 5%;
  width: 80%;
}

/* 2x2 Grid for Bodies of Text */
.grid_2x2 {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10%;
  
}

.marg_right {
  margin-right: 30%;
}

@media only screen and (max-width: 784px) {
  .column {
    column-fill: auto;
    flex: 100%;
    margin-top: 5%;
    width: 80%;
    margin-left:auto;
    margin-right:auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 10%;
    

  }

  .main_marg1 {
    margin: 0;
  }

  .main_marg1 p {
    text-align: center;
  }

  .column h4 {
    text-align: center;
  }
  .column p {
    text-align: center;
  }
  .navl {
    text-align: center;
  }

  .toppadFIVE {
    text-align: center;
  }
}
