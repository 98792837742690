.textbox h4 {
  margin-left: 25%;
  margin-right: 25%;
  text-align: center;
  margin-bottom: 1rem;
}

.textbox p {
  margin-left: 25%;
  margin-right: 25%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
  font-size: medium;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

.textbox img {
  width: 40%;
  height: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1400px) {
  .textbox h4 {
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
  }

  .navl2 {
    padding-right: 5rem;
  }

  .textbox p {
    margin: 0;
    padding: 0 1rem 0 1rem;
    text-align: justify;
    font-size: medium;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .textbox img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
