@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400);

a {
    text-decoration: none;
    transition: color 0.25s ease;
    width: fit-content;
}

a:hover {
    color: #e45555;
}

body {
    margin: 0;
}

.button {
    padding: 10px 20px;
    margin: 0 30px;
    font-family: montserrat;
    color: #e2e2e2;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    border: solid 2px #e2e2e2;
    border-radius: 25px;
    transition: opacity 0.25s ease;
}

.button:hover {
    opacity: 0.5;
    color: #e2e2e2;
}

.left {
    text-align: left !important;
}

.light {
    color: #e2e2e2 !important;
}

.red {
    color: #e45555 !important;
}

/* CSS Rules for mobile devices */

@media only screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        margin-bottom: 10px;
    }

    /* Overflow rule here for class mb-3*/
    .mb-3 {
        overflow: auto;
    }
}

