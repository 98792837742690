#cybersol_div {
  padding: 0 15% 0px 15%;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.grid5 {
  display: grid;
  grid-template-columns: 25% 33% 30%;
  column-gap: 9%;
}

.imgbox {
  margin: 1rem 0 0 3rem;
  width: 100%;
}

#cybersol_text_div {
  padding: 30px 0;
}
/* Note that all industry category pages use this stylesheet */
/* Industries Header */
#title {
  font-size: 350%;
  position: relative;
  top: 20%;
  padding-bottom: 10%;
  align-content: left;
  align-self: stretch;
  background-position: contain;
}

#description {
  font-size: 80px;
}

/* Subheader for specified industry */
.headerInd {
  padding-top: 2%;
  padding-bottom: 2%;
  font-family: open sans;
  font-size: 25px;
  text-decoration: underline;
  text-align: center;
}

/* Main text body */
.boxerInd {
  font-family: open sans;
  padding-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: top;
  padding-bottom: 1%;
  text-align: left;
  vertical-align: bottom;
  line-height: 2;
  position: relative;
  left: 20%;

  /* Paragraph width */
  max-width: 40rem;
}

/* Learn more link list*/
.learnMore {
  position: relative;
  padding-top: 3%;
  padding-right: 20%;
  left: 12%;
  font-size: 200%;
}

.addmargin {
  margin-bottom: 5rem;
}

/* Links Stylization*/
.linktemp {
  font-size: 100%;
  padding-bottom: 10%;
}

.linktemp a {
  color: black;
}

.learnMoreContainer {
  position: relative;
  margin: 1rem 15rem 0 0;
}

/* Redirect button stylization */
.button_main {
  background: linear-gradient(to bottom, #86b0f0 5%, #496fb4 100%);
  background-color: #eae0c2;
  border-radius: 5px;
  border: 2px solid #4b473d;
  cursor: pointer;
  display: inline-block;
  width: 15rem;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

.button_main:hover {
  background: linear-gradient(to bottom, #9ba892 5%, #dbe6c4 100%);
  background-color: #9ba892;
  text-decoration: none;
}

.button_main:active {
  position: relative;
  top: 1px;
  text-decoration: none;
}

#cybersol_text_div h1 {
  margin: 0;
  padding: 0 0 15px 0;
  font-family: open sans;
  font-weight: 300;
  color: #e2e2e2;
  font-size: 75px;
  line-height: 50px;
  text-align: center;
}

.containerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
  width: 80rem;
}
.autowidth {
  width: 100%;
  height: auto;
}

.boxer h1 {
  padding-top: 3%;
  text-align: left;
  font-size: 190%;
}
.boxer p {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 25%;
}

.headerInd2 {
  text-align: left;
}

.parabox {
  text-align: justify;
}

.defense_img {
  content: url("../../img/defense-sm.webp");
  display: block;
  overflow: hidden;
}

.healthcare_img {
  display: block;
  overflow: hidden;
}

.transportation_img1 {
  content: url("../../img/transportation-sm.webp");
  display: block;
  overflow: hidden;
}


@media only screen and (max-width: 1400px) {
  #cybersol_text_div h1 {
    font-size: 28px;
    line-height: 30px;
  }

  #cybersol_div {
    padding: 0 5% 0px 5%;
  }

  #buttons_div {
    flex-direction: column;
  }
  #textbody_img {
    flex-direction: column;
  }

  .button_main {
    margin: 1rem 0 2rem;
  }

  .grid5 {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 9%;
  }

  .imgbox {
    width: 100%;
    margin: 0;
  }

  .parabox {
    font-size: large;
  }
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}
