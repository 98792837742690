#hero_div {
  padding: 0 15% 10px 15%;
  margin-top: -30px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#hero_text_div {
  padding: 20% 0;
  padding-bottom: 15%;
}

#hero_text_div h1 {
  margin: 0;
  padding: 0 0 15px 0;
  font-family: open sans;
  font-weight: 300;
  color: #e2e2e2;
  font-size: 75px;
  line-height: 50px;
  text-align: center;
}

#hero_text_div p {
  font-family: open sans;
  color: #e2e2e2;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  padding-inline: 200px;
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  padding-top: 5%;
}

#buttons_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_main {
  background-color: #4180df;
  border: solid 2px #ffffff;
}


@media (max-width: 768px) {
  #hero_text_div p {
    overflow: visible; /* Or other mobile-specific styles */
    white-space: normal;
    padding-top: 0;
  }
}

@media only screen and (max-width: 1080px) {
  #hero_text_div p {
    font-size: 25px;
    line-height: 50px;
    padding-inline: 0;
  }

  #hero_text_div h1 {
    font-size: 50px;
    line-height: 50px;
    padding-inline: 0;
  }

  #hero_div {
    padding: 0 5% 0px 5%;
  }

  #buttons_div {
    flex-direction: column;
  }
}
