.grid3 {
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 5%;
  margin-top: 2%;
  margin-left: 15%;
  
}

.service_container{
  display: flex;
  flex-direction: row;
}

.bottom{
  display: block;
  align-self: center;
  text-align: center;
  margin-bottom: 1%;
  margin-top: 5%;
  padding: 0 5rem 0 5rem;
}

.column3 {
  flex: 33%;
}

.column3 p {
  flex: 33%;
  text-align: justify;
}

.margtop {
  margin-top: 1rem;
}

/* Mobile Device CSS rules*/
@media only screen and (max-width: 1400px) {
  .grid3 {
    display: inline-grid;
    grid-template-columns: 100%;
    column-gap: 5%;
    margin: 1rem 0 0 0;
    padding: 0 1rem 0 1rem;
  }
}
