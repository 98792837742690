#cybersol_div {
  padding: 0 15% 0px 15%;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0.068)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#cybersol_text_div {
  padding: 30px 0;
}
img {
  height: 300px;
}

.enimg {
  content: url("../../img/encryptionimg-sm.webp");
  object-fit: fill;
  display: block;
  width: 100%;
  height: 40%;
}

.highfont {
  font-size: 120%;
  font-weight: 400;
  opacity: 0.9;
}
.tester {
  color: #ffffff;
  max-width: 800px;

  padding-top: 20%;
  padding-bottom: 20%;
}

.grid9 {
  display: grid;
  grid-template-columns: 40% 40%;
  margin: 2rem 0 1rem 7rem;
  column-gap: 9%;
}

.grid10 {
  display: grid;
  grid-template-columns: 100%;
  margin: 2rem 5rem 1rem 7rem;
  column-gap: 9%;
}

/* Mediaquery for handling display on mobile devices*/
@media only screen and (max-width: 900px) {
  #cybersol_text_div h1 {
    font-size: 28px;
    line-height: 30px;
  }

  .content {
    margin: 0;
  }

  .enimg {
    margin-bottom: 1rem;
  }

  #cybersol_div {
    padding: 0 5% 0px 5%;
  }

  #buttons_div {
    flex-direction: column;
  }

  .grid9 {
    display: block;
    margin: 0;
  }

  .grid10 {
    display: grid;
    grid-template-columns: 100%;
    margin: 2rem 0 1rem 0;
    column-gap: 9%;
  }
}
