#cybersol_div {
  padding: 0 15% 0px 15%;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgb(53, 124, 216), rgba(255, 255, 255, 0)),
    url(/src/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#cybersol_text_div {
  padding: 30px 0;
}
h6 {
  font-size: 1.5rem;
  font-weight: bold;
}

#cybersol_text_div h1 {
  margin: 0;
  padding: 0 0 15px 0;
  font-family: open sans;
  font-weight: 300;
  color: #e2e2e2;
  font-size: 75px;
  line-height: 50px;
  text-align: center;
}

#cybersol_text_div p {
  font-family: open sans;
  color: #e2e2e2;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  padding: 0;
  margin: 0;
}

#buttons_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_marg {
  font-family: open sans;
  margin-left: 19%;
  margin-right: 19%;
}
.centerr {
  text-align: center;
}
.button_main {
  background-color: #4180df;
  border: solid 2px #ffffff;
}
.imageRightPad {
  padding-right: 0px;
}

.toppadTEN {
  padding-top: 8%;
}
.toppadFIVE {
  padding-top: 5%;
}
.toppadAbout {
  padding-top: 2%;
}
.aboutIMG {
  max-width: 100%;
  height: 290px;
  float: right;
  padding-left: 100px;
}

@media only screen and (max-width: 767px) {
  #cybersol_text_div h1 {
    font-size: 28px;
    line-height: 30px;
  }

  #buttons_div {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1400px) {
  .aboutIMG {
    margin-bottom: 15px;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }
}

.aboutUSP {
  color: black;
}
