.gridtoc {
  grid-template-columns: 40% 40%;
  column-gap: 5%;
  display: grid;
  justify-content: center;
  margin: 0;
}

.coltoc {
  display: grid;
  grid-template-columns: 100%;
  margin-left: 7.5%;
  margin-right: 2.5%;
  grid-template-rows: auto;
  justify-content: space-between;
}

.coltoc2 {
  display: grid;
  grid-template-columns: 100%;
}

.rowtoc {
  display: grid;
  grid-template-columns: 40% 40%;
  margin-left: 4.7%;
  column-gap: 7.2%;
  margin-bottom: 20px; /* Adjust as needed */
}

.rectcontainer a {
  color: black;
}

.text1 {
  height: 100%;
}

.rectcontainer {
  height: 200px;
  overflow-y: hidden;
}

.rectcontainer2 {
  height: 200px;
  overflow-y: hidden;
}

.postcontainer {
  width: 100%;
  height: 125px;
  max-width: 100%;
}

.postgrid {
  margin-bottom: 8%;
  display: grid;
  grid-template-columns: 20% 80%;
  height: 43%;
  object-fit: cover; /* Add this line to make the image fill the container */
}

.textanddescription {
  margin: 0% 0% 1% 0;

  margin-left: 3%;
}

@media only screen and (max-width: 1400px) {
  .rectcontainer2 {
    height: 0px;
  }
  .rectcontainer {
    height: 230px;
    overflow-y: hidden;
  }
  .gridtoc {
    grid-template-columns: 100%;
    column-gap: 5%;
    display: grid;
    justify-content: center;
    margin: 0;
  }
  .rowtoc {
    display: grid;
    grid-template-columns: 100%;
    margin-left: 0;
    column-gap: 7.2%;
    margin-bottom: 20px; /* Adjust as needed */
  }
}
