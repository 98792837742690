

.main_marg {
  font-family: open sans;
  margin-left: 19%;
  margin-right: 19%;
}
.centerPhero {
  text-align: center;
}

.grid2 {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 5%;
}

.column2 {
  flex: 33%;
  padding-bottom: 2rem;
}

.column2 p {
  flex: 33%;
  padding-right: 3rem;
}

.column2 img {
  margin-bottom: 1rem;
}

.marg_right {
  margin-right: 30%;
}

.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
}





.industries_info{
  padding-bottom: 5%;
}

.transportation_img1 {
  content: url("../../img/transportation-sm.webp");
  overflow: hidden;
  max-width: 85%;
  padding: 0px;
  overflow-x: hidden; 
  
}

.transportation_img2 {
  content: url("../../img/scanner.png");
  overflow: hidden;
  max-width: 85%;
  padding: 0px;
  overflow-x: hidden; 
  
}

.transportation_img3 {
  content: url("../../img/truck_its.webp");
  overflow: hidden;
  max-width: 85%;
  padding: 0px;
  overflow-x: hidden; 
  
}

.healthcare_img {
  overflow: hidden;
  max-width: 75%;
  padding: 0px;
  overflow-x: hidden; 
}

.defense_img {
  content: url("../../img/defense-sm.webp");
  overflow: hidden;
  max-width: 75%;
  padding: 0px;
  overflow-x: hidden; 
}


@media only screen and (max-width: 800px){
  .transportation_img1 {
    content: url("../../img/transportation-sm.webp");
    overflow: hidden;
    max-width: 100%;
    padding: 0px;
    overflow-x: hidden; 
    
  }

  .transportation_img2 {
    content: url("../../img/scanner.png");
    overflow: hidden;
    max-width: 100%;
    padding: 0px;
    overflow-x: hidden; 
    
  }
  
  .transportation_img3 {
    content: url("../../img/truck_its.webp");
    overflow: hidden;
    max-width: 100%;
    padding: 0px;
    overflow-x: hidden; 
    
  }
  
  .healthcare_img {
    overflow: hidden;
    max-width: 100%;
    padding: 0px;
    overflow-x: hidden; 
  }
  
  .defense_img {
    content: url("../../img/defense-sm.webp");
    overflow: hidden;
    max-width: 100%;
    padding: 0px;
    overflow-x: hidden; 
  }
}

@media only screen and (max-width: 1400px) {
  .column2 {
    column-fill: auto;
    flex: 100%;
    padding-bottom: 2rem;
    margin-top: 5%;
    margin-right: 100%;
    width: 100%;
  }
  .column2 p {
    padding: 0;
  }
  .column2 h4 {
    text-align: center;
    padding: 0;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 10%;
  }
}
