.toppadTEN {
  padding-top: 8%;
}
.toppadFIVE {
  padding-top: 5%;
}
.toppadTWO {
  padding-top: 2%;
}
.toppadAbout {
  padding-top: 2%;
}
.aboutIMG {
  max-width: 100%;
  height: 290px;
  float: right;
  padding-left: 100px;
}

@media only screen and (max-width: 767px) {
  #cybersol_text_div h1 {
    font-size: 28px;
    line-height: 30px;
  }
  .mobileh1 {
    font-size: 28px;
    line-height: 30px;
  }
  .mobileh4 {
    font-size: 15px;
    line-height: 15px;
  }

  #cybersol_div {
    padding: 0 5% 0px 5%;
  }

  #buttons_div {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1400px) {
  .aboutIMG {
    margin-bottom: 15px;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }
}

.aboutUSP {
  color: black;
}
