#header_text {
  font-family: open sans;
  font-weight: bold;
  font-size: 1.5em;
  margin-left: 1rem;
  color: #4180df;
}

#buttons_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_marg {
  font-family: open sans;
  margin-left: 19%;
  margin-right: 19%;
}
.centerr {
  text-align: center;
}
.button_main {
  background-color: #4180df;
  border: solid 2px #ffffff;
}
.imageRightPad {
  padding-right: 0px;
}

.contain {
  position: relative;
  margin: 3%;
  margin-top: 25px;
  background-color: #e2e2e2;
  border-radius: 25px;
  overflow: hidden;
}

.contain2 {
  position: relative;
  margin: 3%;
  margin-top: 25px;
  background-color: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
  padding-top: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}
.slogan {
  background-color: #4180df;
  width: 100%;
  text-align: center;
  font-size: xx-large;
}

.slogan h {
  font-weight: bold;
  color: white;
}

.imgitem {
  display: grid;

  margin: 5% 5% 2% 5%;
}

.imgitem h {
  text-align: center;
  font-family: open sans;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.imgitem img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 2%;
  border-radius: 5px;

  object-fit: scale-down;
}

.grid8 {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.militaryimg {
  content: url("../../img/military-sm.webp");
  display: block;
  overflow: hidden;
}

.scannerimg {
  content: url("../../img/scanner-sm.webp");
  display: block;
  overflow: hidden;
}

.hackerimg {
  content: url("../../img/hacker-sm.webp");
  display: block;
  overflow: hidden;
}

.doctorimg {
  content: url("../../img/doctor-sm.webp");
  display: block;
  overflow: hidden;
}

.brainimg {
  content: url("../../img/brain-sm.webp");
  display: block;
  overflow: hidden;
}

.carimg {
  content: url("../../img/car-sm.webp");
  display: block;
  overflow: hidden;
}

.sateliteimg {
  content: url("../../img/satelite-sm.webp");
  display: block;
  overflow: hidden;
}

.truckimg {
  content: url("../../img/truck-sm.webp");
  display: block;
  overflow: hidden;
}

.medicalimg {
  content: url("../../img/medical-sm.webp");
  display: block;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .grid8 {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

@media only screen and (max-width: 500px) {
  #header_text {
    display: grid;

    font-family: open sans;
    font-weight: bold;
    font-size: 1.5em;
    margin-left: 1rem;
    color: #4180df;
  }

  .grid8 {
    display: grid;
    grid-template-columns: 100%;
  }

  .imgitem {
    display: grid;
    grid-template-columns: 100%;

    margin: 0% 5% 10% 5%;
  }

  .imgitem h {
    text-align: center;
    font-family: open sans;
    font-weight: bold;
    padding: 0;
  }

  .imgitem img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin-bottom: 2%;
    object-fit: scale-down;
  }
}
